import { AuthenticationContext } from '@axa-fr/react-oidc-context';
import {
  DoctCol,
  DoctContainer,
  DoctIcon,
  DoctLogoRegTM,
  DoctRow,
  DoctTypography,
} from '@doct-react/core';
import React, { useContext, useEffect, useState } from 'react';
import Collapsible from './Collapsible';
import playStore from '../assets/images/PLaystore.svg';
import appStore from '../assets/images/Appstore.svg';

const DoctFooter = () => {
  const [doctUrls, setDoctUrls] = useState(null);

  const context = useContext(AuthenticationContext);
  const { login } = context;

  const handleLogIn = () => {
    login(process.env.IDENTITY_ID);
  };
  const handleUserLogin = () => {
    const redirectToBrandingURL = `${process.env.REACT_APP_DOCTHUB_WEB_APP_LINK}/user-redirect?singIn=true`;
    window.location.href = redirectToBrandingURL;
  };

  useEffect(() => {
    setDoctUrls(typeof window !== 'undefined' && window.DoctUrls ? window.DoctUrls : null);
  }, []);
  return (
    <div>
      <footer className={`footer`}>
        <DoctContainer>
          <DoctRow>
            {/* Social Links */}
            <DoctCol
              xs={12}
              sm={5}
              md={3}
              className="footer-social-wrapper d-flex d-sm-block flex-column-reverse"
            >
              <>
                <div className="footer-widget">
                  <div className="footer-nav-logo d-block d-sm-flex">
                    <DoctLogoRegTM rest={true} />
                  </div>
                  <DoctTypography
                    variant="textLabel1"
                    className="footer-tag-line text-grey-800 d-block d-sm-flex"
                  >
                    Empowering Healthcare Fraternity
                  </DoctTypography>
                  <ul className={`footer-widget-social justify-content-sm-start`}>
                    <li>
                      <DoctTypography
                        href="https://www.facebook.com/docthub/"
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 facebook-icon footer-link align-items-center"
                      >
                        <>
                          <DoctIcon width="16" name="facebook" />
                        </>
                      </DoctTypography>
                    </li>
                    <li>
                      <DoctTypography
                        className="text-grey-600 footer-link align-items-center"
                        href="https://twitter.com/docthub"
                        targetBlank={false}
                        variant="link1"
                      >
                        <>
                          <DoctIcon width="16" name="twitter" />
                        </>
                      </DoctTypography>
                    </li>
                    <li>
                      <DoctTypography
                        targetBlank={false}
                        className="text-grey-600 footer-link align-items-center"
                        href="https://www.linkedin.com/company/docthub2017/"
                        variant="link1"
                      >
                        <>
                          <DoctIcon width="16" name="linkedIn" />
                        </>
                      </DoctTypography>
                    </li>
                    <li>
                      <DoctTypography
                        targetBlank={false}
                        className="text-grey-600 footer-link align-items-center"
                        href="https://www.instagram.com/docthub/"
                        variant="link1"
                      >
                        <>
                          <DoctIcon width="16" name="instagram" />
                        </>
                      </DoctTypography>
                    </li>
                    <li>
                      <DoctTypography
                        targetBlank={false}
                        className="text-grey-600 footer-link align-items-center"
                        href="https://www.youtube.com/c/Docthub1/videos"
                        variant="link1"
                      >
                        <>
                          <DoctIcon width="16" name="youTube" />
                        </>
                      </DoctTypography>
                    </li>
                  </ul>
                </div>
                <div className="d-sm-flex flex-row flex-sm-column align-items-sm-start align-items-center mt-0 mt-sm-4">
                  <DoctTypography variant="subtitle2" className="font-weight-medium">
                    Download Mobile App
                  </DoctTypography>
                  <div className="d-sm-flex flex-row flex-sm-column mb-5">
                    <span className="mb-1 mr-2 mr-sm-0">
                      <a
                        target="_blank"
                        href="https://play.google.com/store/search?q=docthub&c=apps&hl=en"
                        rel="noreferrer"
                      >
                        <img src={playStore} />
                      </a>
                    </span>
                    <a
                      target="_blank"
                      href="https://apps.apple.com/in/app/docthub-healthcare-career-app/id1625281489"
                      rel="noreferrer"
                    >
                      <img src={appStore} />
                    </a>
                  </div>
                </div>
              </>
            </DoctCol>
            {/* Products Links */}
            <DoctCol xs={12} sm={0} className="col-sm">
              <>
                <Collapsible title="Our Offerings">
                  <ul>
                    <li>
                      <DoctTypography
                        href={doctUrls ? doctUrls.doct_jobs_url : '#'}
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        Jobs
                      </DoctTypography>
                    </li>
                    <li>
                      <DoctTypography
                        href={doctUrls ? doctUrls.doct_course_url : '#'}
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        Courses
                      </DoctTypography>
                    </li>
                    <li>
                      <DoctTypography
                        href={doctUrls ? doctUrls.doct_event_url : '#'}
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        Events
                      </DoctTypography>
                    </li>
                  </ul>
                </Collapsible>
                <DoctRow>
                  <DoctCol xs={12} className="col-sm">
                    <>
                      <Collapsible title="My Account" className="common_title mt-sm-3">
                        <ul>
                          <li>
                            <div
                              onClick={handleUserLogin}
                              className="text-grey-600 footer-link link-1 cursor-pointer"
                            >
                              Login/Signup
                            </div>
                          </li>
                        </ul>
                      </Collapsible>
                      <Collapsible title="Docthub Mobile App" className="mt-sm-3">
                        <ul>
                          <li>
                            <DoctTypography
                              href={`${doctUrls && doctUrls.doct_brand_url}/healthcare-career-app`}
                              variant="link1"
                              targetBlank={false}
                              className="text-grey-600 footer-link"
                            >
                              Healthcare Career App
                            </DoctTypography>
                          </li>
                        </ul>
                      </Collapsible>
                    </>
                  </DoctCol>
                </DoctRow>
              </>
            </DoctCol>
            <DoctCol xs={12} sm={2} className="col-sm-2 ml-2">
              <>
                <Collapsible title="Tools" className="common_title">
                  <ul>
                    <li>
                      <DoctTypography
                        href={doctUrls?.doct_log_book_url && `${doctUrls?.doct_log_book_url}`}
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        Logbook
                      </DoctTypography>
                    </li>
                    <li>
                      <DoctTypography
                        href={`${doctUrls && doctUrls.doct_brand_url}/resume-builder`}
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        Create Resume
                      </DoctTypography>
                    </li>
                    {/* {HPRTag && (
                      <li>
                        <DoctTypography
                          href={`${doctUrls && doctUrls.doct_hpr_url}`}
                          variant="link1"
                          targetBlank={false}
                          className="text-grey-600 footer-link text-width"
                        >
                          ABDM HPR Registration
                        </DoctTypography>
                      </li>
                    )} */}
                  </ul>
                </Collapsible>
                <Collapsible title="Knowledge" className="common_title mt-sm-3">
                  <ul>
                    {/* {library && (
                      <li>
                        <DoctTypography
                          href={`${doctUrls && doctUrls.doct_library_url}`}
                          variant="link1"
                          targetBlank={false}
                          className="text-grey-600 footer-link"
                        >
                          Library
                        </DoctTypography>
                      </li>
                    )}
                    {coursePages && (
                      <li>
                        <DoctTypography
                          href={`${doctUrls && doctUrls.doct_coursePages_url}`}
                          variant="link1"
                          targetBlank={false}
                          className="text-grey-600 footer-link"
                        >
                          Course Pages
                        </DoctTypography>
                      </li>
                    )}
                    {jobRolePages && (
                      <li>
                        <DoctTypography
                          href={`${doctUrls && doctUrls.doct_jobRolePages_url}`}
                          variant="link1"
                          targetBlank={false}
                          className="text-grey-600 footer-link"
                        >
                          Job Role Pages
                        </DoctTypography>
                      </li>
                    )} */}
                    <li>
                      <DoctTypography
                        href={doctUrls?.doct_blogs_url && `${doctUrls?.doct_blogs_url}`}
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        Blogs
                      </DoctTypography>
                    </li>
                    <li>
                      <DoctTypography
                        href={doctUrls?.doct_news_url && `${doctUrls?.doct_news_url}`}
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        News
                      </DoctTypography>
                    </li>
                  </ul>
                </Collapsible>
              </>
            </DoctCol>

            <DoctCol xs={12} sm={0} className="col-sm">
              <>
                <Collapsible title="For Organizations">
                  <ul>
                    <li>
                      <DoctTypography
                        href={
                          (doctUrls?.doct_recruiters_url && doctUrls?.doct_recruiters_url) || ''
                        }
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        Post a Job
                      </DoctTypography>
                    </li>
                    <li>
                      <DoctTypography
                        href={`${
                          (doctUrls?.doct_institutes_url && doctUrls?.doct_institutes_url) || ''
                        }`}
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        List a Course
                      </DoctTypography>
                    </li>
                    <li>
                      <DoctTypography
                        href={
                          (doctUrls?.doct_event_organizers && doctUrls?.doct_event_organizers) || ''
                        }
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        Create an Event
                      </DoctTypography>
                    </li>

                    <li>
                      <DoctTypography
                        href={
                          (doctUrls?.doct_membership_url && doctUrls?.doct_membership_url) || ''
                        }
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link text-width"
                      >
                        Membership Management
                      </DoctTypography>
                    </li>

                    <li>
                      <DoctTypography
                        href={
                          (doctUrls?.doct_enterprise_solutions_url &&
                            doctUrls?.doct_enterprise_solutions_url) ||
                          ''
                        }
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        Enterprise Dashboard
                      </DoctTypography>
                    </li>
                  </ul>
                </Collapsible>
                <Collapsible title="Enterprise Account" className="common_title mt-sm-3">
                  <ul>
                    <li onClick={handleLogIn}>
                      <DoctTypography
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link cursor-pointer"
                      >
                        Enterprise Login
                      </DoctTypography>
                    </li>
                    <li>
                      <DoctTypography
                        href={`${
                          doctUrls && doctUrls.doct_account_url
                        }/create-enterprise-account?account_type=Business`}
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        Create Account
                      </DoctTypography>
                    </li>
                  </ul>
                </Collapsible>
                <Collapsible title="Enterprise Mobile App" className="mt-sm-3">
                  <ul>
                    <li>
                      <DoctTypography
                        href={`${doctUrls && doctUrls.doct_brand_url}/enterprise-solutions`}
                        variant="link1"
                        targetBlank={false}
                        className="text-grey-600 footer-link"
                      >
                        QR Check-In App
                      </DoctTypography>
                    </li>
                  </ul>
                </Collapsible>
              </>
            </DoctCol>

            <DoctCol xs={12} className="col-sm offset-0 offset-sm-5 offset-md-0">
              <Collapsible title="Company" className="common_title">
                <ul>
                  <li>
                    <DoctTypography
                      href={`${doctUrls && doctUrls.doct_brand_url}/about-us`}
                      variant="link1"
                      targetBlank={false}
                      className="text-grey-600 footer-link"
                    >
                      About Docthub
                    </DoctTypography>
                  </li>
                  <li>
                    <DoctTypography
                      href={`${doctUrls && doctUrls.doct_brand_url}/media-releases`}
                      variant="link1"
                      targetBlank={false}
                      className="text-grey-600 footer-link"
                    >
                      Media Releases
                    </DoctTypography>
                  </li>
                  <li>
                    <DoctTypography
                      href={`${doctUrls && doctUrls.doct_brand_url}/terms-conditions`}
                      variant="link1"
                      targetBlank={false}
                      className="text-grey-600 footer-link"
                    >
                      Terms of Use
                    </DoctTypography>
                  </li>
                  <li>
                    <DoctTypography
                      href={`${doctUrls && doctUrls.doct_brand_url}/privacy-policy`}
                      variant="link1"
                      targetBlank={false}
                      className="text-grey-600 footer-link"
                    >
                      Privacy Policy
                    </DoctTypography>
                  </li>
                  <li>
                    <DoctTypography
                      href={`${doctUrls && doctUrls.doct_brand_url}/payment-policy`}
                      variant="link1"
                      targetBlank={false}
                      className="text-grey-600 footer-link"
                    >
                      Payment Policy
                    </DoctTypography>
                  </li>
                  <li>
                    <DoctTypography
                      href={`${doctUrls && doctUrls.doct_brand_url}/cookies-policy`}
                      variant="link1"
                      targetBlank={false}
                      className="text-grey-600 footer-link"
                    >
                      Cookies Policy
                    </DoctTypography>
                  </li>
                  <li>
                    <DoctTypography
                      href={`${doctUrls && doctUrls.doct_brand_url}/disclaimer-policy`}
                      variant="link1"
                      targetBlank={false}
                      className="text-grey-600 footer-link"
                    >
                      Disclaimer
                    </DoctTypography>
                  </li>
                  <li>
                    <DoctTypography
                      href={`${doctUrls && doctUrls.doct_brand_url}`}
                      variant="link1"
                      targetBlank={false}
                      className="text-grey-600 footer-link"
                    >
                      Docthub Home
                    </DoctTypography>
                  </li>
                  <li>
                    <DoctTypography
                      href={`${doctUrls && doctUrls.doct_brand_url}/contact-us`}
                      variant="link1"
                      targetBlank={false}
                      className="text-grey-600 footer-link"
                    >
                      Contact us
                    </DoctTypography>
                  </li>
                </ul>
              </Collapsible>
            </DoctCol>
            <DoctCol xs={12} sm={5} className="col-md-auto">
              <Collapsible title="Docthub Enterprise" className="policies_title">
                <ul>
                  <li>
                    <DoctTypography
                      href={`${
                        doctUrls && doctUrls.doct_enterprise_url
                      }/enterprise-terms-conditions`}
                      variant="link1"
                      targetBlank={false}
                      className="text-grey-600 footer-link"
                    >
                      Terms & Conditions
                    </DoctTypography>
                  </li>
                  <li>
                    <DoctTypography
                      href={`${doctUrls && doctUrls.doct_enterprise_url}/enterprise-privacy-policy`}
                      variant="link1"
                      targetBlank={false}
                      className="text-grey-600 footer-link"
                    >
                      Privacy Policy
                    </DoctTypography>
                  </li>
                  <li>
                    <DoctTypography
                      href={`${doctUrls && doctUrls.doct_enterprise_url}/enterprise-payment-policy`}
                      variant="link1"
                      targetBlank={false}
                      className="text-grey-600 footer-link"
                    >
                      Payment Policy
                    </DoctTypography>
                  </li>
                </ul>
              </Collapsible>
            </DoctCol>
          </DoctRow>

          {/* Copyright Footer */}
          <DoctTypography
            variant="textLabel2"
            className="text-center text-grey-400 copyright-footer"
          >
            <>Copyright © {new Date().getFullYear()} Docthub. All rights reserved.</>
          </DoctTypography>
        </DoctContainer>
      </footer>
    </div>
  );
};

export default DoctFooter;
