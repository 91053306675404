import React, { useEffect, useState } from 'react';
import { DoctRadioGroup, DoctTextField } from '@doct-react/app';
import { DoctCol, DoctRow, DoctTypography } from '@doct-react/core';
import { EMAIL_VALIDATION } from '../../../constants/constants';
import LocationField from '../LocationField';
import MobileWhatsAppForm from '../MobileWhatsAppForm/MobileWhatsAppForm';
import { FORM_NAMES_STEPS } from './attendeeForm.constants';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { WORK_STATUS_TYPE } from '../../../helper/enums/eventStatus';
import axios from 'axios';
import CustomAutoComplete from '../../../components/CustomAutoComplete';

export default function AttendeeForm({
  control,
  errors,
  touched,
  watch,
  setValue,
  savedValue,
  clearErrors,
  register,
}) {
  return (
    <DoctRow>
      <DoctCol xs={6} className="mx-auto">
        <DoctTypography variant="subtitle3" className="mb-3 mt-0">
          PERSONAL DETAILS
        </DoctTypography>
        <DoctTypography variant="subtitle2" className="my-0">
          Gender
        </DoctTypography>
        <div className="mx-n2 mb-2">
          <DoctRadioGroup
            name={FORM_NAMES_STEPS.gender}
            id="radio1"
            control={control}
            options={[
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
            ]}
            validationRules={{
              required: "It's Required Field",
            }}
            isErrors={errors}
          />
        </div>
        <DoctTextField
          showStar
          name={FORM_NAMES_STEPS.fullName}
          label="Certificate Name"
          id="fullName"
          pattern="\s*(\S\s*){3,}"
          control={control}
          isErrors={errors}
          defaultValue=""
          validationRules={{
            minLength: {
              value: 3,
              message: 'Name should be minimum 3 characters long',
            },
            required: "It's Required Field",
            pattern: {
              value: /^(?=.*[a-zA-Z])[a-zA-Z\s]*[a-zA-Z]{3,}[a-zA-Z\s]*\s*$/,
              message: 'Name should contain only letters',
            },
          }}
          touched={touched}
        />
        <DoctTypography variant="caption2" className="text-initial mb-3 d-block mt-1">
          This name will be shown in Event Certificate.
        </DoctTypography>
        <LocationField
          savedValue={savedValue}
          control={control}
          errors={errors}
          touched={touched}
          watch={watch}
          setValue={setValue}
          inputProps={{
            country: {
              disabled: true,
            },
          }}
        />
        <DoctTypography variant="subtitle3" className="registration-form-gap-top">
          CONTACT DETAILS
        </DoctTypography>
        <MobileWhatsAppForm
          control={control}
          errors={errors}
          touched={touched}
          watch={watch}
          setValue={setValue}
          clearErrors={clearErrors}
          className={'d-flex input-column mx-n2'}
        />
        <DoctTextField
          showStar
          name={FORM_NAMES_STEPS.email}
          label="Email address"
          id="email"
          control={control}
          isErrors={errors}
          touched={touched}
          className="mb-5"
          validationRules={{
            required: "It's Required Field",
            pattern: {
              value: EMAIL_VALIDATION,
              message: 'Entered value does not match email format',
            },
          }}
        />
        <ProfessionalDetailsComponent
          control={control}
          errors={errors}
          touched={touched}
          watch={watch}
          setValue={setValue}
          register={register}
        />
        <DoctTypography variant="subtitle3" className="registration-form-gap-top">
          OTHER DETAILS (OPTIONAL)
        </DoctTypography>
        <DoctTextField
          name={FORM_NAMES_STEPS.practiceLicenseNumber}
          label="Practice License Number"
          id="practiceLicenseNumber"
          control={control}
          isErrors={errors}
          touched={touched}
          validationRules={{}}
          className="mb-3"
        />
        <DoctTextField
          name={FORM_NAMES_STEPS.membershipID}
          label="Membership ID"
          id="membershipID"
          control={control}
          isErrors={errors}
          touched={touched}
          validationRules={{}}
          className="mb-4"
        />
      </DoctCol>
    </DoctRow>
  );
}

const ProfessionalDetailsComponent = ({ control, errors, touched, watch, setValue, register }) => {
  const optionsWorkStatus = [
    {
      value: WORK_STATUS_TYPE.WorkingProfessional,
      label: 'Working Professional',
    },
    { value: WORK_STATUS_TYPE.Student, label: 'Student' },
  ];

  const [workStatusType, setWorkStatusType] = useState(
    watch(FORM_NAMES_STEPS.workStatus) || WORK_STATUS_TYPE.WorkingProfessional,
  );

  const [educationOptions, setEducationOptions] = useState([]);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [specialtyValue, setSpecialtyValue] = useState({});

  useEffect(
    () => setWorkStatusType(watch(FORM_NAMES_STEPS.workStatus)),
    [watch(FORM_NAMES_STEPS.workStatus)],
  );

  const handleWorkStatus = (isChecked, value) => {
    if (isChecked) {
      setValue(FORM_NAMES_STEPS.workStatus, value);
      setWorkStatusType(value);
    }
  };

  const getQualificationsNames = async (searchText) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/coursecenter/qualifications/names${
          searchText ? `?searchText=${searchText}` : ''
        }`,
      );
      const data = response?.data?.map((item = {}) => ({
        value: item.name,
        label: item.name,
        id: item.id,
      }));
      setEducationOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSpecialtyNames = async (searchText) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/contentcenter/specialties/names${
          searchText ? `?searchText=${searchText}` : ''
        }`,
      );
      const data = response?.data?.map((item = {}) => ({
        value: item.name,
        label: item.name,
        id: item.id,
      }));
      setSpecialtyOptions(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (val = '', field) => {
    if (field === FORM_NAMES_STEPS.qualification) {
      if (!val || val === undefined) setValue(FORM_NAMES_STEPS.qualification, '');
      else if (typeof val === 'string') setValue(FORM_NAMES_STEPS.qualification, val);
      else setValue(FORM_NAMES_STEPS.qualification, val?.value ?? '');
    }
    if (field === FORM_NAMES_STEPS.specialtyIds) {
      if (!val || val === undefined || typeof val === 'string')
        setValue(FORM_NAMES_STEPS.specialtyIds, '');
      else setValue(FORM_NAMES_STEPS.specialtyIds, val?.value ? [val?.value] : '');
    }
  };

  useEffect(() => {
    getQualificationsNames();
    getSpecialtyNames();
  }, []);

  const watchQualification = watch(FORM_NAMES_STEPS.qualification) || '';
  const watchSpecialty = watch(FORM_NAMES_STEPS.specialtyIds) || '';
  const qualificationValue = {
    value: watchQualification,
    label: watchQualification,
  };

  useEffect(() => {
    const val = Array.isArray(watchSpecialty) ? watchSpecialty[0] : watchSpecialty;
    const res = {
      value: val,
      label: val,
    };
    setSpecialtyValue((prev) => {
      if (prev.value !== res.value) {
        return res;
      }
      return prev;
    });
  }, [watchSpecialty, setSpecialtyValue]);

  return (
    <div className="mb-5">
      <DoctTypography variant="subtitle3" className="mb-3 mt-0">
        PROFESSIONAL DETAILS
      </DoctTypography>
      <DoctTypography variant="subtitle2" className="my-0">
        Work Status
      </DoctTypography>
      <div className="mx-n2 mb-2 for-radio-primary-round">
        <RadioGroup
          sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '9px' }}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={workStatusType}
          value={workStatusType}
          name={FORM_NAMES_STEPS.workStatus}
        >
          {optionsWorkStatus.map((item, i) => (
            <FormControlLabel
              key={i}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                  lineHeight: '14px',
                  color: '#242424',
                  fontWeight: '400',
                },
              }}
              control={<Radio />}
              onChange={(e) => handleWorkStatus(e.target.checked, item.value)}
              value={item.value}
              defaultChecked={item.value === workStatusType}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </div>
      {workStatusType === WORK_STATUS_TYPE.WorkingProfessional && (
        <DoctTextField
          name={FORM_NAMES_STEPS.currentOrganization}
          label="Organization"
          id="currentOrganization"
          control={control}
          isErrors={errors}
          touched={touched}
          validationRules={{
            required: 'Organization is Required',
            validate: (value) => {
              if (!value.trim()) return 'Organization is Required';
              return true;
            },
          }}
          className="mb-3"
        />
      )}
      <DoctRow>
        {workStatusType === WORK_STATUS_TYPE.WorkingProfessional && (
          <DoctCol md={6}>
            <DoctTextField
              name={FORM_NAMES_STEPS.currentDesignation}
              label="Designation"
              id="currentDesignation"
              control={control}
              isErrors={errors}
              touched={touched}
              validationRules={{
                required: 'Designation is Required',
                validate: (value) => {
                  if (!value.trim()) return 'Designation is Required';
                  return true;
                },
              }}
              className="mb-3"
            />
          </DoctCol>
        )}
        <DoctCol md={workStatusType === WORK_STATUS_TYPE.WorkingProfessional ? 6 : 12}>
          <CustomAutoComplete
            control={control}
            register={register}
            touched={touched}
            errors={errors}
            field={FORM_NAMES_STEPS.specialtyIds}
            value={specialtyValue}
            options={specialtyOptions}
            handleChange={handleChange}
            label="Specialty *"
            validationRules={{ required: 'Specialty is required' }}
            className="mb-3"
          />
        </DoctCol>
      </DoctRow>
      <CustomAutoComplete
        control={control}
        register={register}
        touched={touched}
        errors={errors}
        field={FORM_NAMES_STEPS.qualification}
        value={qualificationValue}
        options={educationOptions}
        handleChange={handleChange}
        freeSolo={true}
        label="Education *"
        validationRules={{
          required: 'Education is required',
          validate: (value) => {
            if (!value.trim()) return 'Education is required';
            return true;
          },
        }}
        className="mb-3"
      />
    </div>
  );
};
