export const EventStatus = Object.freeze({
  ACTIVE: 'Active',
  CLOSED: 'Closed',
  DRAFT: 'Draft',
  COMPLETED: 'Completed',
  ACTIVATIONREQUESTED: 'ActivationRequested',
  REJECTED: 'Rejected',
});

export const EventStatusDisplayName = Object.freeze({
  [EventStatus.ACTIVE]: 'Registration Active',
  [EventStatus.CLOSED]: 'Registration Closed',
  [EventStatus.DRAFT]: 'Draft',
  [EventStatus.COMPLETED]: 'Completed',
  [EventStatus.ACTIVATIONREQUESTED]: 'Under Review',
  [EventStatus.REJECTED]: 'Rejected',
});

export const NUMBER_OF_GATHERING = Object.freeze({
  UNLIMITED: 'Unlimited',
  LIMITED: 'Limited',
});

export const WORK_STATUS_TYPE = Object.freeze({
  WorkingProfessional: 'WorkingProfessional',
  Student: 'Student',
});
