import React from 'react';
import { DoctContainer, DoctTypography } from '@doct-react/core';
import PageSEO from '../shared/SEO/PageSEO';
import LandingPageHeader from '../layout/dashboardBrandingCommonLayout/LandingPageHeader/LandingPageHeader';
import FooterForPolicy from './DoctFooter';
import { CommonContent, CommonHeading, CommonTitle } from './CommonContent';
import { privacyPolicyEnterprise } from './PrivacyPolicyEnterprise';

const PrivacyPolicyEnterpriseFooter = () => {
  const renderLiWithContent = (index, className = '') => (
    <li
      key={index}
      dangerouslySetInnerHTML={{
        __html: privacyPolicyEnterprise[index]?.content,
      }}
      className={`text-grey-500 lh-35 ${className}`}
    />
  );

  return (
    <>
      <PageSEO
        title={'Privacy Policy | Business policy | Docthub Enterprise'}
        description={
          'Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tell You about Your privacy rights and how the law protects You.'
        }
      />
      <LandingPageHeader enterpriseHeader />
      <div className="privacy-policy-modal static-modal">
        <CommonHeading heading={privacyPolicyEnterprise[0]?.heading} />
        <DoctContainer>
          <ul className="static-list static-list-item">
            {Array.from({ length: 5 }, (_, i) => renderLiWithContent(i + 1))}
          </ul>
          <CommonTitle title={privacyPolicyEnterprise[6]?.title} />
          {renderLiWithContent(7, 'mt-3')}
          <CommonTitle title={privacyPolicyEnterprise[8]?.title} />
          {renderLiWithContent(9, 'mt-3')}
          <ul className="static-list static-list-item">
            {Array.from({ length: 9 }, (_, i) => renderLiWithContent(i + 10, 'mt-2'))}
          </ul>
          <CommonTitle title={privacyPolicyEnterprise[19]?.title} />
          <CommonContent
            content={privacyPolicyEnterprise[20]?.content}
            className={'text-grey-500 lh-35'}
          />
          <CommonTitle title={privacyPolicyEnterprise[21]?.title} />
          <CommonContent
            content={privacyPolicyEnterprise[22]?.content}
            className={'text-grey-500 lh-35'}
          />
          <CommonTitle title={privacyPolicyEnterprise[23]?.title} />
          <CommonContent
            content={privacyPolicyEnterprise[24]?.content}
            className={'text-grey-500 lh-35'}
          />
          <CommonTitle title={privacyPolicyEnterprise[25]?.title} />
          <CommonContent
            content={privacyPolicyEnterprise[26]?.content}
            className={'text-grey-500 lh-35'}
          />
          <ul className="static-list static-list-item">
            {Array.from({ length: 3 }, (_, i) => renderLiWithContent(i + 27, 'mt-3'))}
          </ul>
          {renderLiWithContent(30, 'mt-3')}
          <CommonTitle title={privacyPolicyEnterprise[31]?.title} />
          {renderLiWithContent(32, 'my-3')}
          <ul className="static-list static-list-item">
            {Array.from({ length: 4 }, (_, i) => renderLiWithContent(i + 33))}
          </ul>
          <CommonTitle title={privacyPolicyEnterprise[37]?.title} />
          <CommonTitle title={privacyPolicyEnterprise[38]?.title} className={`mt-lg-0`} />
          <CommonContent
            content={privacyPolicyEnterprise[39]?.content}
            className={'text-grey-500 lh-35 mt-4'}
          />
          <CommonContent
            content={privacyPolicyEnterprise[40]?.content}
            className={'text-grey-500 lh-35 m-0'}
          />
          <ul className="static-list static-list-item">
            {Array.from({ length: 2 }, (_, i) => renderLiWithContent(i + 41, 'mt-2'))}
          </ul>
          <CommonContent
            content={privacyPolicyEnterprise[43]?.content}
            className={'text-grey-500 lh-35'}
          />
          <CommonTitle title={privacyPolicyEnterprise[44]?.title} />
          <CommonContent
            content={privacyPolicyEnterprise[45]?.content}
            className={'text-grey-500 lh-35'}
          />
          <CommonTitle title={privacyPolicyEnterprise[46]?.title} />
          {renderLiWithContent(47)}
          {renderLiWithContent(48)}
          {renderLiWithContent(49)}
          <CommonTitle title={privacyPolicyEnterprise[50]?.title} />
          <ul className="static-list static-list-item">
            {renderLiWithContent(51, 'mt-3')}
            {renderLiWithContent(52)}
          </ul>
          <CommonTitle title={privacyPolicyEnterprise[53]?.title} />
          <CommonTitle title={privacyPolicyEnterprise[54]?.title} className={`mt-lg-0`} />
          <CommonTitle title={privacyPolicyEnterprise[55]?.title} />
          <CommonTitle title={privacyPolicyEnterprise[56]?.title} className={`mt-lg-0`} />
          <CommonTitle title={privacyPolicyEnterprise[57]?.title} />
          <DoctTypography variant="h6" className={`lh-35 text-grey-500 mt-3`}>
            Docthub Health Tech Private Limited
          </DoctTypography>
          <DoctTypography variant="h6" className={`lh-35 text-grey-500`}>
            202, GNFC Info Tower, Near Pakwan Cross Road, SG Highway
          </DoctTypography>
          <DoctTypography variant="h6" className={`lh-35 text-grey-500 mt-3`}>
            Ahmedabad. 380054. Gujarat. India.
          </DoctTypography>
          <DoctTypography variant="h6" className={`lh-35 text-grey-500`}>
            Telephone: (+91) 79 26855579
          </DoctTypography>
          <DoctTypography variant="h6" className={`lh-35 text-grey-500`}>
            <span className="text-black">Email</span>:
            <a href="mailto:office@docthub.com" className="text-primary">
              office@docthub.com{' '}
            </a>
          </DoctTypography>
          <CommonTitle title={privacyPolicyEnterprise[58]?.title} />
          {renderLiWithContent(59, 'mt-3')}
          <ul className="static-list static-list-item">
            {renderLiWithContent(60, 'mt-2')}
            {renderLiWithContent(61)}
          </ul>
        </DoctContainer>
      </div>
      <FooterForPolicy />
    </>
  );
};

export default PrivacyPolicyEnterpriseFooter;
