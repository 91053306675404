import { JOB_CENTER } from '../../../api/apiEndpoints';

export async function sendRollBackHistory(prepObjectForFormData, token) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_API_URL}/${JOB_CENTER}/subscribers/subscriber-rollback-history`,
      {
        method: 'POST',
        body: JSON.stringify(prepObjectForFormData),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );
    if (response?.ok) {
      // eslint-disable-next-line no-unused-vars
      const data = await response?.clone().json();
    }
  } catch (error) {
    throw new Error(error);
  }
}
