export const FORM_NAMES_STEPS = {
  gender: 'gender',
  fullName: 'name',
  country: 'country',
  state: 'state',
  city: 'city',
  mobileCountryCode: 'mobileCountryCode',
  mobileNumber: 'mobileNumber',
  whatsappCountryCode: 'whatsappCountryCode',
  whatsappNumber: 'whatsappNumber',
  mobileNumberAsWhatsApp: 'mobileNumberAsWhatsApp',
  email: 'emailId',
  practiceLicenseNumber: 'licenseNo',
  membershipID: 'memberId',
  currentOrganization: 'currentOrganization',
  currentDesignation: 'currentDesignation',
  specialtyIds: 'specialtyIds',
  workStatus: 'workStatus',
  qualification: 'qualification',
};
