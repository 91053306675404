import { EMAIL_VALIDATION } from '../../../../constants/constants';
import { WORK_STATUS_TYPE } from '../../../../helper/enums/eventStatus';

const filedManageAttendee = {
  gender: {
    id: 'gender',
    name: 'gender',
    options: [
      { value: 'Male', label: 'Male' },
      { value: 'Female', label: 'Female' },
    ],
    validationRules: {
      required: "It's Required Field",
    },
  },
  name: {
    id: 'name',
    name: 'name',
    label: 'Certificate Name',
    validationRules: {
      minLength: {
        value: 3,
        message: 'Enter name at least 3 character long',
      },
      maxLength: {
        value: 100,
        message: 'Max 100 character allowed',
      },
      required: "It's Required Field",
    },
    showStar: true,
  },
  country: {
    id: 'country',
    name: 'country',
    validationRules: {
      required: "It's Required Field",
    },
  },
  state: {
    id: 'state',
    name: 'state',
    validationRules: {
      required: "It's Required Field",
    },
  },
  city: {
    id: 'city',
    name: 'city',
    validationRules: {
      required: "It's Required Field",
    },
  },
  phoneNo: {
    countryCode: {
      name: 'countryCode',
      validationRules: {
        required: "It's Required Field",
      },
    },
    number: {
      name: 'Number',
      validationRules: {
        required: "It's Required Field",
      },
    },
  },
  whatsAppNumber: {
    countryCode: {
      name: 'countryCode',
      validationRules: {
        required: "It's Required Field",
      },
    },
    number: {
      name: 'Number',
      validationRules: {
        required: "It's Required Field",
      },
    },
  },
  email: {
    id: 'email',
    name: 'email',
    label: 'Email address',
    validationRules: {
      required: "It's Required Field",
      pattern: {
        value: EMAIL_VALIDATION,
        message: 'Entered value does not match email format',
      },
    },
    showStar: true,
  },
  specialty: {
    label: 'Specialty',
    name: 'specialtyIds',
    validationRules: {
      required: 'Specialty is required',
    },
    hideSearchIcon: true,
  },
  designation: {
    id: 'designation',
    name: 'currentDesignation',
    label: 'Designation',
    validationRules: {
      required: 'Designation is Required',
      validate: (value) => {
        if (!value.trim()) return 'Designation is Required';
        return true;
      },
    },
  },
  organization: {
    id: 'organization',
    name: 'currentOrganization',
    label: 'Organization',
    validationRules: {
      required: 'Organization is Required',
      validate: (value) => {
        if (!value.trim()) return 'Organization is Required';
        return true;
      },
    },
  },
  licenseNo: {
    id: 'licenseNo',
    name: 'licenseNo',
    label: 'Practice License Number',
    validationRules: {},
  },
  memberId: {
    id: 'memberId',
    name: 'memberId',
    label: 'Membership ID',
    validationRules: {},
  },
  workStatus: {
    id: 'workStatus',
    name: 'workStatus',
    options: [
      { value: WORK_STATUS_TYPE.WorkingProfessional, label: 'Working Professional' },
      { value: WORK_STATUS_TYPE.Student, label: 'Student' },
    ],
    validationRules: {
      required: "It's Required Field",
    },
  },
  qualification: {
    id: 'qualification',
    name: 'qualification',
    label: 'Education',
    validationRules: {
      required: "It's Required Field",
    },
  },
};

export { filedManageAttendee };
