/* eslint-disable react/no-unescaped-entities */
import { DoctContainer, DoctTypography } from '@doct-react/core';
import React from 'react';
import PageSEO from '../shared/SEO/PageSEO';
import { CommonContent, CommonHeading, CommonTitle } from './CommonContent';
import { termsAndConditionsEnterprise } from './TermsAndConditionsEnterprise';
import LandingPageHeader from '../layout/dashboardBrandingCommonLayout/LandingPageHeader/LandingPageHeader';
import FooterForPolicy from './DoctFooter';

const TermsAndConditionsEnterpriseFooter = () => {
  return (
    <>
      <PageSEO
        title={'Terms and Conditions | Docthub Enterprise'}
        description={
          'Docthub – Enterprise Solutions is for business users to post their content and get user responses on related Docthub’s user platform. '
        }
      />
      <LandingPageHeader enterpriseHeader />
      <div className="privacy-policy-modal static-modal">
        <CommonHeading heading={termsAndConditionsEnterprise[0]?.heading} />
        <DoctContainer>
          <DoctTypography variant="h6" className={` text-black mt-5 lh-35`}>
            Docthub – Enterprise Solutions
          </DoctTypography>
          <DoctTypography variant="h6" className={`lh-35 text-primary-500`}>
            <a href="/">(enterprise.docthub.com)</a>
          </DoctTypography>
          <CommonContent
            content={termsAndConditionsEnterprise[1]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[2]?.content}
            className={` text-grey-500 m-0`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[3]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[4]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[5]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[6]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[7]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[8]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[9]?.content}
            className={`lh-35 text-grey-500`}
          />
          <ul className="static-list static-list-item">
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[10]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[11]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[12]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[13]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[14]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[15]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[16]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[17]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[18]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[19]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[20]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[21]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[22]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[23]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[24]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[25]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
          </ul>
          <CommonTitle title={termsAndConditionsEnterprise[26]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[27]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[28]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[29]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[30]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[31]?.content}
            className={`text-underline font-weight-bold text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[32]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[33]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[34]?.content}
            className={`lh-35 text-grey-500`}
          />
          <ul className="static-list static-list-item">
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[35]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[36]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[37]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[38]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[39]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[40]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[41]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
          </ul>
          <CommonContent
            content={termsAndConditionsEnterprise[42]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[43]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[44]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[45]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[46]?.content}
            className={`text-underline font-weight-bold text-grey-500`}
          />
          <li
            dangerouslySetInnerHTML={{
              __html: termsAndConditionsEnterprise[47]?.content,
            }}
            className={'text-grey-500 lh-35'}
          />
          <CommonTitle title={termsAndConditionsEnterprise[48]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[49]?.content}
            className={`lh-35 text-grey-500`}
          />
          <ul className="static-list static-list-item">
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[50]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[51]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[52]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[53]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[54]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[55]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
          </ul>
          <li
            dangerouslySetInnerHTML={{
              __html: termsAndConditionsEnterprise[56]?.content,
            }}
            className={'text-grey-500 lh-35'}
          />
          <CommonTitle title={termsAndConditionsEnterprise[57]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[58]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[59]?.content}
            className={`text-underline font-weight-bold text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[60]?.content}
            className={'text-grey-500 lh-35'}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[61]?.content}
            className={`text-underline font-weight-bold text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[62]?.content}
            className={`lh-35 text-grey-500`}
          />
          <ul className="static-list static-list-item">
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[63]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[64]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[65]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
          </ul>
          <li
            dangerouslySetInnerHTML={{
              __html: termsAndConditionsEnterprise[66]?.content,
            }}
            className={'text-grey-500 lh-35'}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[67]?.content}
            className={`text-underline font-weight-bold text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[68]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[69]?.content}
            className={`text-underline font-weight-bold text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[70]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[71]?.content}
            className={`text-underline font-weight-bold text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[72]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[73]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[74]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[75]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[76]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[77]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[78]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[79]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[80]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[81]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[82]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[83]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[84]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[85]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[86]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[87]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[88]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[89]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[90]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[91]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[92]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[93]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[94]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[95]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[96]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[97]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[98]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[99]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[100]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[101]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[102]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[103]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonContent
            content={termsAndConditionsEnterprise[104]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[105]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[106]?.content}
            className={`lh-35 text-grey-500`}
          />
          <CommonTitle title={termsAndConditionsEnterprise[107]?.title} />
          <CommonContent
            content={termsAndConditionsEnterprise[108]?.content}
            className={'text-grey-500 lh-35'}
          />
          <ul className="static-list static-list-item">
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[109]?.content,
              }}
              className={'text-grey-500 lh-35 mt-2'}
            />
            <li
              dangerouslySetInnerHTML={{
                __html: termsAndConditionsEnterprise[110]?.content,
              }}
              className={'text-grey-500 lh-35'}
            />
          </ul>
        </DoctContainer>
      </div>
      <FooterForPolicy />
    </>
  );
};

export default TermsAndConditionsEnterpriseFooter;
