/* eslint-disable react/no-unescaped-entities */
import { DoctContainer } from '@doct-react/core';
import React from 'react';
import PageSEO from '../shared/SEO/PageSEO';
import { CommonContent, CommonHeading, CommonTitle } from './CommonContent';
import { disclaimerPolicyEnterprise } from './DisclaimerPolicyEnterprise';
import LandingPageHeader from '../layout/dashboardBrandingCommonLayout/LandingPageHeader/LandingPageHeader';
import FooterForPolicy from './DoctFooter';

const generateListItems = (startIndex, endIndex, marginTop = 0) => {
  const listItems = [];
  for (let i = startIndex; i <= endIndex; i++) {
    const content = disclaimerPolicyEnterprise[i]?.content;
    listItems.push(
      <li
        key={i}
        dangerouslySetInnerHTML={{ __html: content }}
        className={`text-grey-500 lh-35${marginTop > 0 ? ' mt-' + marginTop : ''}`}
      />,
    );
  }
  return listItems;
};

const DisclaimerPolicyEnterpriseFooter = () => {
  return (
    <>
      <PageSEO
        title={'Disclaimer Policy | Docthub Enterprise'}
        description={
          'The information contained on the Service is for general information purposes only. The Company assumes no responsibility for errors or omissions in the contents of the Service.'
        }
      />
      <LandingPageHeader enterpriseHeader />
      <div className="privacy-policy-modal static-modal">
        <CommonHeading heading={disclaimerPolicyEnterprise[0]?.heading} />
        <DoctContainer>
          <ul className="static-list static-list-item">{generateListItems(1, 6)}</ul>
          <CommonContent
            content={disclaimerPolicyEnterprise[7]?.content}
            className={'text-grey-500 lh-35'}
          />
          <CommonTitle title={disclaimerPolicyEnterprise[8]?.title} />
          {generateListItems(9, 12, 2)}
          <CommonContent
            content={disclaimerPolicyEnterprise[13]?.content}
            className={'text-grey-500 lh-35'}
          />
          <CommonTitle title={disclaimerPolicyEnterprise[14]?.title} />
          <ul className="static-list static-list-item">{generateListItems(15, 17, 3)}</ul>
          <CommonTitle title={disclaimerPolicyEnterprise[18]?.title} />
          <CommonContent
            content={disclaimerPolicyEnterprise[19]?.content}
            className={'text-grey-500 lh-35'}
          />
          <ul className="static-list static-list-item">{generateListItems(20, 21, 2)}</ul>
        </DoctContainer>
      </div>
      <FooterForPolicy />
    </>
  );
};

export default DisclaimerPolicyEnterpriseFooter;
