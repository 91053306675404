import { AuthenticationContext } from '@axa-fr/react-oidc-context';
import React, { useContext, useEffect, useState } from 'react';
import headerLogo from '../../../assets/icons/dashboard_header_logo.svg';
import { DoctButton, DoctContainer, DoctTypography } from '@doct-react/core';
import './LandingPageHeader.scss';
import { CONTINUE_URL } from '../../../constants/constants';

const LandingPageHeader = () => {
  const context = useContext(AuthenticationContext);
  const { login } = context;

  const [doctUrls, setDoctUrls] = useState(null);

  // Fetched urls from window and added in a local state
  useEffect(() => {
    setDoctUrls(typeof window !== 'undefined' && window.DoctUrls ? window.DoctUrls : null);
  }, []);

  const handleLogin = () => {
    login(process.env.IDENTITY_ID);
  };

  const handleCreateAccount = () => {
    window.location.href = `${process.env.REACT_APP_ACCOUNT_APP_BUSINESS_LINK}&${CONTINUE_URL}=${process.env.REACT_APP_ENTERPRISE_APP_LINK}`;
  };

  return (
    <div className="header_container_dashboard">
      <DoctContainer>
        <div className="d-flex align-items-center justify-content-between">
          <img src={headerLogo} />
          <div className="d-flex align-items-center">
            <DoctTypography
              href={doctUrls ? doctUrls.doct_enterprise_solutions_url : '#'}
              variant={'textLabel1'}
              className="text-grey-600 font-weight-medium subtitle-2 nav-items-header"
            >
              Home
            </DoctTypography>
            <DoctTypography
              href={doctUrls ? doctUrls.doct_recruiters_url : '#'}
              variant={'link1'}
              className="text-grey-600 font-weight-medium subtitle-2 nav-items-header"
            >
              Recruiter
            </DoctTypography>
            <DoctTypography
              href={doctUrls ? doctUrls.doct_institutes_url : '#'}
              variant={'link1'}
              className="text-grey-600 font-weight-medium subtitle-2 nav-items-header"
            >
              Institutes
            </DoctTypography>
            <DoctTypography
              href={doctUrls ? doctUrls.doct_event_organizers : '#'}
              variant={'link1'}
              className="text-grey-600 font-weight-medium subtitle-2 nav-items-header"
            >
              Events
            </DoctTypography>
            <DoctTypography
              href={doctUrls ? doctUrls.doct_membership_url : '#'}
              variant={'link1'}
              className="text-grey-600 font-weight-medium subtitle-2 nav-items-header"
            >
              Memberships
            </DoctTypography>
          </div>
          <div className="d-flex align-items-center">
            <DoctButton
              onButtonClickHandler={handleLogin}
              size="medium"
              variant="outlined"
              text="Enterprise Login"
              className="mr-2"
            />
            <DoctButton
              onButtonClickHandler={handleCreateAccount}
              text="Create Account"
              type="semantic-info"
              size="medium"
            />
          </div>
        </div>
      </DoctContainer>
    </div>
  );
};

export default LandingPageHeader;
