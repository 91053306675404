export const EventType = Object.freeze([
  {
    value: 'InternationalConference',
    label: 'International Conference',
  },
  {
    value: 'NationalConference',
    label: 'National Conference',
  },
  {
    value: 'StateConference',
    label: 'State Conference',
  },
  {
    value: 'RegionalConference',
    label: 'Regional Conference',
  },
  {
    value: 'CityChapterEvent',
    label: 'City Chapter Event',
  },
  {
    value: 'PreConferenceWorkshop',
    label: 'Pre-Conference + Workshop',
  },
  {
    value: 'MeetUps',
    label: 'Meet-Ups',
  },
  {
    value: 'Workshops',
    label: 'Workshops',
  },
  {
    value: 'HandsOnWorkshop',
    label: 'Hands-On Workshop',
  },
  {
    value: 'Webinars',
    label: 'Webinars',
  },
  {
    value: 'ContinuingMedicalEducationCME',
    label: 'Continuing Medical Education (CME)',
  },
  {
    value: 'ExhibitionsExpos',
    label: 'Exhibitions/Expos',
  },
  {
    value: 'Seminars',
    label: 'Seminars',
  },
  {
    value: 'MasterClasses',
    label: 'Master Classes',
  },
  {
    value: 'CrashCourse',
    label: 'Crash Course',
  },
  {
    value: 'CertificationCourse',
    label: 'Certification Course',
  },
  {
    value: 'PublicHealthEvents',
    label: 'Public Health Events',
  },
  {
    value: 'RuralHealthCamp',
    label: 'Rural Health Camp',
  },
  {
    value: 'CollegeCulturalEvent',
    label: 'College Cultural Event',
  },
  {
    value: 'MedicalCollegeAnnualFest',
    label: 'Medical College Annual Fest',
  },
  {
    value: 'AwardsandRecognitionEvents',
    label: 'Awards and Recognition Events',
  },
]);

export const eventOrderStatus = Object.freeze({
  created: {
    value: 'Created',
  },
  confirmed: {
    value: 'Confirmed',
  },
  cancelled: {
    value: 'Cancelled',
  },
  failed: {
    value: 'Failed',
  },
});

export const eventOrderType = Object.freeze({
  online: {
    value: 'Online',
  },
  offline: {
    value: 'Offline',
  },
  complementary: {
    value: 'Complementary',
  },
});

export const EventRegistrationType = Object.freeze([
  { value: 'Paid', label: 'Paid Event' },
  { value: 'Free', label: 'Free Event' },
]);

export const NumberOfGathering = Object.freeze([
  { label: 'Limited', value: 'Limited' },
  { label: 'Unlimited', value: 'Unlimited' },
]);
