import React, { useEffect, useState } from 'react';
import { DoctModal, DoctPhoneCodeDropdown, DoctForm, DoctTextField } from '@doct-react/app';
import { ActionRowLayout } from '../../ExpandableArea';
import { ActionButton, TitleDescription } from './CommonComponent';
import { useGetUserQuery, useUpdateProfileMutation } from '../../../../../../components';
import { InputColumn, InputColumnTiny } from '../../../../../../shared';
import fieldProfileSetting from '../../../../Profile/components/field.profileSettings';
import { useNotifySuccessFailed } from '../../../../../../hooks';
import { excludeUnusedProfileData } from '../../../../Profile/components/AdminProfile/helperFunction.AdminProfile';
import { DoctTypography } from '@doct-react/core';

function formDefaultValue(mobileNumber) {
  return {
    dial_code: {
      label: '+91',
      value: '+91',
    },
    [fieldProfileSetting.mobileNumber.name]: mobileNumber?.split('-').slice(-1)[0],
  };
}

function Form({ control, errors, touched, reset, defaultValues }) {
  useEffect(() => {
    reset(defaultValues);
  }, []);

  return (
    <>
      <InputColumn className="d-flex">
        <InputColumnTiny>
          <DoctPhoneCodeDropdown disabled control={control} isErrors={errors} />
        </InputColumnTiny>
        <DoctTextField
          name={fieldProfileSetting.mobileNumber.name}
          label={fieldProfileSetting.mobileNumber.label}
          id={fieldProfileSetting.mobileNumber.id}
          validationRules={fieldProfileSetting.mobileNumber.validationRules}
          control={control}
          isErrors={errors}
          touched={touched}
          showStar={false}
        />
      </InputColumn>
      <DoctTypography variant="textLabel2" className="my-3 text-grey-600">
        This number will be used for your communication with Docthub regarding enterprise account.
        Two Step verification will be using this number.
      </DoctTypography>
    </>
  );
}

export default function ChangeMobileNumber() {
  const { data: userData } = useGetUserQuery('');
  const { mobileNumber } = userData || {};

  const [showModal, setShowModal] = useState(false);

  const [useUpdateProfile, { isLoading, isSuccess, isError }] = useUpdateProfileMutation();

  const { handleSubmit, control, errors, reset, touched } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const hookFormProps = { control, errors, touched, reset };

  const initApiCall = handleSubmit((data) => {
    const { dial_code, [fieldProfileSetting.mobileNumber.name]: mobileNumber } = data || {};
    useUpdateProfile(
      excludeUnusedProfileData({
        ...userData,
        [fieldProfileSetting.mobileNumber.name]: `${dial_code?.value}-${mobileNumber}`,
        ImageFileName: 'image',
        ResumeFileName: 'resume',
      }),
    );
  });

  useNotifySuccessFailed({
    isSuccess,
    isError,
    errorMessage: 'Mobile Number is Already Exist',
    onSuccessHandler: () => setShowModal(false),
  });

  return (
    <>
      <ActionRowLayout
        onClick={() => {}}
        showCursor={false}
        title={
          <TitleDescription
            title={' Mobile:'}
            description={mobileNumber?.replace('-', ' ') || '-'}
          />
        }
        rightArea={
          <ActionButton
            btnText={mobileNumber ? 'CHANGE' : 'ADD'}
            onClick={() => {
              setShowModal(true);
            }}
          />
        }
      />
      {showModal && (
        <DoctModal
          isPrimaryBtnDisable={isLoading}
          iconName={''}
          primaryBtnText={'Submit'}
          open
          className={'disable_modal_outside_click'}
          handlePrimaryButtonClick={initApiCall}
          handleClose={() => {
            setShowModal(false);
          }}
          title={mobileNumber ? 'Edit Mobile Number' : 'Add New Mobile Number'}
          width={360}
        >
          <Form {...hookFormProps} defaultValues={formDefaultValue(mobileNumber)} />
        </DoctModal>
      )}
    </>
  );
}
