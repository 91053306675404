import { DoctIcon, DoctTypography } from '@doct-react/core';
import React, { useState } from 'react';

const Collapsible = ({ children, title, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onChangeHandler = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <div>
      <div
        className={`footer-widget ${className ? ` ${className}` : ''} ${isOpen ? 'open' : ''}`}
        onClick={onChangeHandler}
      >
        <DoctTypography
          variant="subtitle2"
          className="text-grey-800 m-0 footer-link-title font-weight-bold"
        >
          <>
            <span>{title}</span>
            <DoctIcon
              className="d-block d-sm-none footer-widget-icon"
              width="24"
              height="24"
              name="down"
            />
          </>
        </DoctTypography>
        <div className={`footer-collapsible`}>{children}</div>
      </div>
    </div>
  );
};

export default Collapsible;
